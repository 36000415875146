.App {
  text-align: center;
}
.Full{
  width:100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.site-card-border-less-wrapper {
  padding: 20px;
  background: #ECECEC;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid #aaa;
  margin: 10px 0;
  padding: 10px;
}
.react-grid-layout {
  position: relative;
  border: 1px solid #aaa;
  transition: height 200ms ease;
}
.react-grid-item {
  border: 1px solid #aaa;
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

#x {
  position: absolute;
  color: #000;
  font-size:x-small;
  background:#FFF;
  border-radius: 20px;
  top: -5px;
  right: -5px;
  box-shadow: 1px 2px 5px rgb(174, 175, 175) inset, 1px 2px 5px rgb(174, 175, 175);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
img {
  max-width: 100%;
}
.site-layout{
  width:85%;
  min-width: 1000px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.show-grid .row>.col, .show-grid .row>[class^=col-] {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: #FFF;
}
.preview div,.preview > * {
 padding: 0px !important;
}
.preview .col-sm-8 {
  max-width: 100% !important;
}
.MuiBox-root{
  text-align: center;
}
.preview{
  padding-left: 35px !important;
}


.bg-light {
  background-color: #191529!important;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #dcae0a;
}
.topleftbanner{
  padding-right: 0;
}
.torightbanner{
  padding-left: 0;
}
.mainbanner{
  padding: 0 3px 0 3px;
}
.footer{
  background-color: #393939!important;
  color:  #e6e6e6;
  padding-top: 48px;
}
.newsletter-subscribe{
  background-color: #f5f5f5!important;
  color: rgb(0, 0, 0);
  padding: 15px;
}
.footer h5{
  padding: 0 0 16px;
  font-family: MontrealSerial-Bold, sans-serif;
  font-size: 14px;
  cursor: auto;
  color: #fff;
}
h2{
  font-family: MontrealSerial-Bold, sans-serif;
  font-size: 18px;
  font-weight: bold;
   
}
.linkGroup_3qKNM ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}
.linkGroup_3qKNM li {
  margin: 0;
  padding: 0 0 16px;
  line-height: 1;
}
.linkGroup_3qKNM li a{
  color:  #e6e6e6;
  font-family: MontrealSerial-Bold, sans-serif;
  font-size: 12px;
}
.Nav-banner{
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.css-1jvu78r {
  font-size: 14px;
  display: inherit;
  min-width: 0px;
  margin-left: 0.5em;
}
.mini-cart-total-label sup{
  background: #ff0000;
  color: #fff;
  padding: 2px 8px;
  border-radius: 50%;
  font-size: 14px;
  top: 0em;
}
.card svg{
  color: rgb(165, 165, 165);
  float: right;
  margin: 2px;
}
.card span svg{
color: rgb(165, 165, 165);
float: none;
margin: 2px;
}
.copyright{
  padding: 16px 0 16px 0;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}
.card input{
  width:100%;
  height: 46px;
  text-align: center;
  font-size: 18px !important;  
}
.react-numeric-input{
  width:30%;
  height: 46px;
  background-color:#e6e6e6;
}
.inputandbtn{

  text-align: center;
}
.card button{
  font-family: montrealserial-regular,sans-serif !important;
    width: 60% !important;
    background-color: #caa622!important;
    font-size: 15px !important;  
    margin-top: 10px;
}
.card button svg{
    float: left;
    color: #fff!important;
}
.divLoader{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.cardtxt{
  font-family: montrealserial-regular,sans-serif !important;
  font-size: 14px;
}
.card img{
    margin: 15px 5px 0 5px;
}
.card .prductimgbg{
  margin: 10px !important; 
}
.card h4{
  font-family: montrealserial-regular,sans-serif;
  padding-top:10px;
  font-weight: bold;
  font-size: 15px;  
}

.priceright{
    margin-top: 18px;;
}
.price {
  color: #39464e;
  font-size: 48px;
}
.price sup {
  color: #39464e;
  font-size: 15px;
}
.price sup.pricex {
  color: #39464e;
  font-size: 12px;
}
 .disprice {
  color: #c20e1a;
  font-size: 48px;
}
.disprice sup {
  color: #c20e1a;
  font-size: 15px;
}
.disprice sup.pricex {
  color: #c20e1a;
  font-size: 12px;
}
.dispricetxt{
  color: #c20e1a;
}
.card button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
.container-fluid{height: 94% !important;}
.ant-popover{
  width:250px;
}
.card button:hover {
  opacity: 0.7;
}
.homeMessage{
  font-family: montrealserial-regular,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: .05em;
  font-size: 12px;
  line-height: 1.385;
}
.homeh2Message{
  font-family: montrealserial-regular,sans-serif;
  letter-spacing: 0.5px;
  font-size: 20px;
  line-height: 1.15;
  margin-top: 25px;
}
.accleft{
  padding-right: 0px;
}
.accright{
  padding-left: 0px;
}
.col-sm-3 .ant-menu {
  width:100% !important;
}
.alert .ant-radio-group{
  width: 100%;
}
.quantity{
  display:flex;
  width:160px;
}

/* it will support chrome and firefox */
.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance:none;
}

.quantity input,.quantity button{
  width:40px;
  padding:.1em;
  text-align:center;
  font-weight:900;
  background:white;
  border:1px solid #aaa;
}

.quantity input{
  border-left:none;
  border-right:none;
}
.or-icon {
padding-left: 110px !important;
padding-top: 100px !important ;
}
.ant-menu-submenu-title span{
vertical-align: middle;
}
.basketadded{
font-family: montrealserial-regular,sans-serif;
color:#52c41a;
}
.basketadded svg{
 vertical-align: baseline;
}
#x {
  position: absolute;
  color: #000;
  font-size: x-small;
  background:#FFF;
  border-radius: 20px;
  top: 0px;
  right: 0px;
  z-index:1000;
  box-shadow: 1px 2px 5px rgb(174, 175, 175) inset, 1px 2px 5px rgb(174, 175, 175);
}